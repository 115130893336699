import React from "react"
import { graphql } from "gatsby"

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import SidebarMenu from "../components/SidebarMenu"
import GalleryComponent from "../components/Gallery"
import PageHeader from "../components/PageHeader"
import CardsWithSwiper from "../components/CardsWithSwiper"
import ReactMarkdown from "../components/ReactMarkdown"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

export default function Page({ data, pageContext }) {
  const { locale, menu } = pageContext

  useChangeLanguage({ locale })

  const images =
    data.strapiPage &&
    data.strapiPage.gallery &&
    data.strapiPage.gallery.map(picture => picture.localFile.childImageSharp)

  return (
    <MainLayout alternativeSlug={data.strapiPage.localizations.data?.[0]?.attributes?.slug} locale={locale}>
      <Seo
        title={`${data.strapiPage.seo.title}`}
        description={`${data.strapiPage.seo.description}`}
      />

      <PageHeader
        image={data.strapiPage.header.image}
        header={data.strapiPage.header.title}
        detail={data.strapiPage.header.description}
        buttons={data.strapiPage.header.buttons}
        bannerBg={data.strapiPage.blogTag.backgroundColor}
      />

      <div className="container page-content">
        <aside className="page-content-menu">
          <SidebarMenu menu={menu.urls} />
        </aside>

        <ReactMarkdown
          children={data.strapiPage.content.data.content}
          className="ck-content page-content-text"
        />
      </div>
      {images && (
        <GalleryComponent
          pictures={images}
          backgroundColor={data.strapiPage.blogTag.backgroundColor}
        />
      )}

      <CardsWithSwiper nodes={data.allStrapiBlogPost.nodes.sort((a, b) => {
        // Prioritize pinned posts
        if (a.pin && !b.pin) return -1;
        if (!a.pin && b.pin) return 1;

        // If both dates are null or both are pinned, sort based on createdAt
        if ((!a.date && !b.date) || (a.pin && b.pin)) return new Date(b.createdAt) - new Date(a.createdAt);

        // If only one date is null, place it at the end
        if (!a.date) return 1;
        if (!b.date) return -1;

        // If neither date is null and neither are pinned, sort based on date first, then createdAt
        const dateDiff = new Date(b.date) - new Date(a.date);
        if (dateDiff !== 0) return dateDiff;

        return new Date(b.createdAt) - new Date(a.createdAt);
      })} />
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!, $blogTag: String!) {
    strapiPage(slug: { eq: $slug }) {
      slug
      content {
        data {
          content
        }
      }
      localizations {
        data {
          attributes {
            slug
          }
        }
      }
      header {
        title
        buttons {
          textColor
          textHoverColor
          backgroundColor
          backgroundHoverColor
          link
          text
        }
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        description
        title
        keywords
      }
      blogTag {
        id
        backgroundColor
      }
      type {
        id
      }
      gallery {
        localFile {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 285
              height: 161
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    allStrapiBlogPost(
      filter: { tag: { id: { eq: $blogTag } } }
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        id
        slug
        header {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date
        pin
        tag {
          name
        }
      }
    }
  }
`
