import { Link } from "gatsby"
import React from "react"

const SidebarMenu = ({ menu }) => {
  return (
    <>
      {menu.map(item => (
        <>
          {item.link && item.link.length > 0 ? (
            <Link activeClassName="activeLink" to={item.link}>
              {item.text}
            </Link>
          ) : (
            <h3>{item.text}</h3>
          )}
          {item.strapi_children && item.strapi_children.length > 0 && (
            <ul>
              {item.strapi_children.map(childItem => (
                <li>
                  <Link activeClassName="activeLink" to={childItem.link}>
                    {childItem.text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      ))}
    </>
  )
}

export default SidebarMenu
